* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* needed for container min-height */
}
body {
  font-family: sans-serif;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

